var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.transformArguments = exports.FIRST_KEY_INDEX = void 0;
  exports.FIRST_KEY_INDEX = 1;
  function transformArguments(key, item) {
    return ["CF.COUNT", key, item];
  }
  exports.transformArguments = transformArguments;
  return exports;
}