import { dew as _ADDDew } from "./ADD";
import { dew as _CARDDew } from "./CARD";
import { dew as _EXISTSDew } from "./EXISTS";
import { dew as _INFODew } from "./INFO";
import { dew as _INSERTDew } from "./INSERT";
import { dew as _LOADCHUNKDew } from "./LOADCHUNK";
import { dew as _MADDDew } from "./MADD";
import { dew as _MEXISTSDew } from "./MEXISTS";
import { dew as _RESERVEDew } from "./RESERVE";
import { dew as _SCANDUMPDew } from "./SCANDUMP";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  const ADD = _ADDDew();
  const CARD = _CARDDew();
  const EXISTS = _EXISTSDew();
  const INFO = _INFODew();
  const INSERT = _INSERTDew();
  const LOADCHUNK = _LOADCHUNKDew();
  const MADD = _MADDDew();
  const MEXISTS = _MEXISTSDew();
  const RESERVE = _RESERVEDew();
  const SCANDUMP = _SCANDUMPDew();
  exports.default = {
    ADD,
    add: ADD,
    CARD,
    card: CARD,
    EXISTS,
    exists: EXISTS,
    INFO,
    info: INFO,
    INSERT,
    insert: INSERT,
    LOADCHUNK,
    loadChunk: LOADCHUNK,
    MADD,
    mAdd: MADD,
    MEXISTS,
    mExists: MEXISTS,
    RESERVE,
    reserve: RESERVE,
    SCANDUMP,
    scanDump: SCANDUMP
  };
  return exports;
}