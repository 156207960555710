import * as _genericTransformers2 from "@redis/client/dist/lib/commands/generic-transformers";
var _genericTransformers = "default" in _genericTransformers2 ? _genericTransformers2.default : _genericTransformers2;
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.transformReply = exports.transformArguments = exports.FIRST_KEY_INDEX = void 0;
  const generic_transformers_1 = _genericTransformers;
  exports.FIRST_KEY_INDEX = 1;
  function transformArguments(key, items, options) {
    const args = ["BF.INSERT", key];
    if (options?.CAPACITY) {
      args.push("CAPACITY", options.CAPACITY.toString());
    }
    if (options?.ERROR) {
      args.push("ERROR", options.ERROR.toString());
    }
    if (options?.EXPANSION) {
      args.push("EXPANSION", options.EXPANSION.toString());
    }
    if (options?.NOCREATE) {
      args.push("NOCREATE");
    }
    if (options?.NONSCALING) {
      args.push("NONSCALING");
    }
    args.push("ITEMS");
    return (0, generic_transformers_1.pushVerdictArguments)(args, items);
  }
  exports.transformArguments = transformArguments;
  var generic_transformers_2 = _genericTransformers;
  Object.defineProperty(exports, "transformReply", {
    enumerable: true,
    get: function () {
      return generic_transformers_2.transformBooleanArrayReply;
    }
  });
  return exports;
}