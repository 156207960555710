import { dew as _Dew } from ".";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.transformReply = exports.transformArguments = exports.IS_READ_ONLY = exports.FIRST_KEY_INDEX = void 0;
  exports.FIRST_KEY_INDEX = 1;
  exports.IS_READ_ONLY = true;
  function transformArguments(key, quantiles) {
    const args = ["TDIGEST.QUANTILE", key];
    for (const quantile of quantiles) {
      args.push(quantile.toString());
    }
    return args;
  }
  exports.transformArguments = transformArguments;
  var _1 = _Dew();
  Object.defineProperty(exports, "transformReply", {
    enumerable: true,
    get: function () {
      return _1.transformDoublesReply;
    }
  });
  return exports;
}