import { dew as _bloomDew } from "./bloom";
import { dew as _countMinSketchDew } from "./count-min-sketch";
import { dew as _cuckooDew } from "./cuckoo";
import { dew as _tDigestDew } from "./t-digest";
import { dew as _topKDew } from "./top-k";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  const bloom_1 = _bloomDew();
  const count_min_sketch_1 = _countMinSketchDew();
  const cuckoo_1 = _cuckooDew();
  const t_digest_1 = _tDigestDew();
  const top_k_1 = _topKDew();
  exports.default = {
    bf: bloom_1.default,
    cms: count_min_sketch_1.default,
    cf: cuckoo_1.default,
    tDigest: t_digest_1.default,
    topK: top_k_1.default
  };
  return exports;
}