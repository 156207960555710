var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.transformReply = exports.transformArguments = exports.IS_READ_ONLY = exports.FIRST_KEY_INDEX = void 0;
  exports.FIRST_KEY_INDEX = 1;
  exports.IS_READ_ONLY = true;
  function transformArguments(key) {
    return ["CF.INFO", key];
  }
  exports.transformArguments = transformArguments;
  function transformReply(reply) {
    return {
      size: reply[1],
      numberOfBuckets: reply[3],
      numberOfFilters: reply[5],
      numberOfInsertedItems: reply[7],
      numberOfDeletedItems: reply[9],
      bucketSize: reply[11],
      expansionRate: reply[13],
      maxIteration: reply[15]
    };
  }
  exports.transformReply = transformReply;
  return exports;
}