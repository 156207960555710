import { dew as _commandsDew } from "./commands";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = void 0;
  var commands_1 = _commandsDew();
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return commands_1.default;
    }
  });
  return exports;
}