import * as _genericTransformers2 from "@redis/client/dist/lib/commands/generic-transformers";
var _genericTransformers = "default" in _genericTransformers2 ? _genericTransformers2.default : _genericTransformers2;
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.transformArguments = exports.IS_READ_ONLY = exports.FIRST_KEY_INDEX = void 0;
  const generic_transformers_1 = _genericTransformers;
  exports.FIRST_KEY_INDEX = 1;
  exports.IS_READ_ONLY = true;
  function transformArguments(key, items) {
    return (0, generic_transformers_1.pushVerdictArguments)(["CMS.QUERY", key], items);
  }
  exports.transformArguments = transformArguments;
  return exports;
}