import { dew as _ADDDew } from "./ADD";
import { dew as _COUNTDew } from "./COUNT";
import { dew as _INCRBYDew } from "./INCRBY";
import { dew as _INFODew } from "./INFO";
import { dew as _LIST_WITHCOUNTDew } from "./LIST_WITHCOUNT";
import { dew as _LISTDew } from "./LIST";
import { dew as _QUERYDew } from "./QUERY";
import { dew as _RESERVEDew } from "./RESERVE";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  const ADD = _ADDDew();
  const COUNT = _COUNTDew();
  const INCRBY = _INCRBYDew();
  const INFO = _INFODew();
  const LIST_WITHCOUNT = _LIST_WITHCOUNTDew();
  const LIST = _LISTDew();
  const QUERY = _QUERYDew();
  const RESERVE = _RESERVEDew();
  exports.default = {
    ADD,
    add: ADD,
    COUNT,
    count: COUNT,
    INCRBY,
    incrBy: INCRBY,
    INFO,
    info: INFO,
    LIST_WITHCOUNT,
    listWithCount: LIST_WITHCOUNT,
    LIST,
    list: LIST,
    QUERY,
    query: QUERY,
    RESERVE,
    reserve: RESERVE
  };
  return exports;
}