import { dew as _ADDDew } from "./ADD";
import { dew as _BYRANKDew } from "./BYRANK";
import { dew as _BYREVRANKDew } from "./BYREVRANK";
import { dew as _CDFDew } from "./CDF";
import { dew as _CREATEDew } from "./CREATE";
import { dew as _INFODew } from "./INFO";
import { dew as _MAXDew } from "./MAX";
import { dew as _MERGEDew } from "./MERGE";
import { dew as _MINDew } from "./MIN";
import { dew as _QUANTILEDew } from "./QUANTILE";
import { dew as _RANKDew } from "./RANK";
import { dew as _RESETDew } from "./RESET";
import { dew as _REVRANKDew } from "./REVRANK";
import { dew as _TRIMMED_MEANDew } from "./TRIMMED_MEAN";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.transformDoublesReply = exports.transformDoubleReply = exports.pushCompressionArgument = void 0;
  const ADD = _ADDDew();
  const BYRANK = _BYRANKDew();
  const BYREVRANK = _BYREVRANKDew();
  const CDF = _CDFDew();
  const CREATE = _CREATEDew();
  const INFO = _INFODew();
  const MAX = _MAXDew();
  const MERGE = _MERGEDew();
  const MIN = _MINDew();
  const QUANTILE = _QUANTILEDew();
  const RANK = _RANKDew();
  const RESET = _RESETDew();
  const REVRANK = _REVRANKDew();
  const TRIMMED_MEAN = _TRIMMED_MEANDew();
  exports.default = {
    ADD,
    add: ADD,
    BYRANK,
    byRank: BYRANK,
    BYREVRANK,
    byRevRank: BYREVRANK,
    CDF,
    cdf: CDF,
    CREATE,
    create: CREATE,
    INFO,
    info: INFO,
    MAX,
    max: MAX,
    MERGE,
    merge: MERGE,
    MIN,
    min: MIN,
    QUANTILE,
    quantile: QUANTILE,
    RANK,
    rank: RANK,
    RESET,
    reset: RESET,
    REVRANK,
    revRank: REVRANK,
    TRIMMED_MEAN,
    trimmedMean: TRIMMED_MEAN
  };
  function pushCompressionArgument(args, options) {
    if (options?.COMPRESSION) {
      args.push("COMPRESSION", options.COMPRESSION.toString());
    }
    return args;
  }
  exports.pushCompressionArgument = pushCompressionArgument;
  function transformDoubleReply(reply) {
    switch (reply) {
      case "inf":
        return Infinity;
      case "-inf":
        return -Infinity;
      case "nan":
        return NaN;
      default:
        return parseFloat(reply);
    }
  }
  exports.transformDoubleReply = transformDoubleReply;
  function transformDoublesReply(reply) {
    return reply.map(transformDoubleReply);
  }
  exports.transformDoublesReply = transformDoublesReply;
  return exports;
}